// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  demo: false,
  apiURL: "https://checkout-server-homolog.conqueronline.com.br/",
  //apiURL: "https://checkout-server-homolog.ingress.conqueronline.com.br/",
  //apiURL: "http://localhost:8080/",
  loginApiURL: "",
  vindiApi: "https://sandbox-app.vindi.com.br/api/v1/",
  vindiPublicKey:
      "MGh5dmwwbXpxQXN2ZV91YkZZOGxqdU15dHBKdjdHc21YdS1aaC15WnJXQTo=",
  auth: {
    clientID: "",
    domain: "",
    audience: "",
    callbackURL: "",
  },
  recaptcha: {
    siteKey: '6LfxIFsmAAAAABxzLl6ogZXPB3etlxvG-pD9LCu0',
  },
  urlLPConquer: "https://escolaconquer.com.br/",
  conquerWeekConquerPlusThreeProfiles:  "e92d362a-fa88-4df5-b1d9-9ac59ac29737",
  conquerWeekComboConquerPlusEnglish:  "85fdb5bf-4fce-411d-8a35-bb3d92c25097",
  conquerWeekConquerPlusLifeTime:  "7625940c-3bdf-4263-8bc1-1702fcc52784",
  conquerWeekPOSLeadershipTechnology:  "b4fd1be9-d2b9-43e7-a222-f1ee391b15ed",
  conquerWeekPOSDigitalMarketing:  "838fe16e-e7e8-4f49-a335-0b2b4aa4f968",
  conquerWeekPOSStrategicBusinessManagement:  "849af5a1-f12e-421f-8e58-bd153e32b005",
  conquerWeekPOSProjectManagement:  "7c7e6765-c942-430a-8155-ed95dd2818c9",
  conquerWeekPOSLeadershipPeopleManagement:  "8fbec53e-2cfa-4878-a324-882f5c4c651e",
  conquerWeekPOSBusinessIntelligenceAnalytics:  "42af7bcf-8f47-463f-9d9b-3aaa10ff5864",
  conquerWeekPOSCommercialManagement:  "5260732d-00d3-4203-a3e0-73d783ff521e",
  conquerWeekConquerEnglishUP:  "8d7fc1b3-efab-455f-bd94-2d23dae01846"
}