import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app.routing";

import { AppComponent } from "./app.component";

import { AuthService } from "./services/auth/auth.service";

import { MatButtonModule, MatCheckboxModule, MatIconModule } from "@angular/material";

import { ToastrModule } from "ngx-toastr";

import { GtagModule } from "angular-gtag";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    ToastrModule.forRoot({
      timeOut: 20000,
      extendedTimeOut: 10000,
    }),
    MatIconModule,
  ],
  declarations: [AppComponent],
  providers: [AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
