import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { timer } from 'rxjs/observable/timer';

import * as auth0 from 'auth0-js';

import { environment } from 'environments/environment';

@Injectable()
export class AuthService {

  refreshSubscription: any;
  offset: any;

  auth0: auth0.WebAuth;

  constructor(public router: Router) {
    this.auth0 = new auth0.WebAuth({
      clientID: environment.auth.clientID,
      domain: environment.auth.domain,
      responseType: 'token',
      audience: environment.auth.audience,
      redirectUri: environment.auth.callbackURL,
      scope: 'openid email offline_access'
    });
  }

  public login(email: string, password: string): Promise<string> {
    return new Promise<string>((resolve, reject) =>
      this.auth0.login({
        realm: 'Username-Password-Authentication',
        email,
        password
      }, (err, authResult) => {
        console.log(err, authResult);
        if (err) {
          if (err.error === 'access_denied') {
            resolve('ACCESS_DENIED');
          } else {
            console.log(JSON.stringify(err));
            resolve('UNKNOWN_ERROR');
          }
        } else {
          this.setSession(authResult);
          resolve('OK');
        }
      }));
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public doAuth0Login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {

    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        localStorage.setItem('role', 'user');
        window.location.hash = '';
        this.setSession(authResult);
      } else if (err) {
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }



  public setSession(authResult): void {
    // Set the time that the access token will expire at
    // this.offset = 1 * 60 * 1000;
    if (authResult.expiresIn) {
      this.offset = authResult.expiresIn * 1000 - (5 * 60 * 1000); //5min before expiration
    } else {
      this.offset = 60 * 60 * 1000;
    }
    const expiresAt = JSON.stringify(new Date().getTime() + this.offset);
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    this.scheduleRenewal();
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('role');
    localStorage.removeItem('onboarding');

    this.unscheduleRenewal();

    // Go back to the home route
    this.router.navigate(['/login']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }


  public renewToken(): void {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(result);
      }
    });
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }
    this.unscheduleRenewal();

    const expiresAt = JSON.parse(localStorage.getItem('expires_at')) - 15000; //15s before actual expiration

    const now = Date.now();
    const source = timer(Math.max(1, expiresAt - now));
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
    });
  }

  public unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

}
