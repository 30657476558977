import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  
  {
    path: "",
    redirectTo: "checkout",
    pathMatch: "full"
  },
  {
    path: "",
    children: [
      {
        path: "",
        loadChildren: "./open/checkout/checkout.module#CheckoutModule",
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: [],
})
export class AppRoutingModule {}
